import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48289265"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hello" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_h7 = _resolveComponent("h7")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.msg), 1),
    _createVNode(_component_h7, null, {
      default: _withCtx(() => [
        _createTextVNode("P:1.616.813.7954 | ©Copyright 2003-2024 H2R. All rights reserved")
      ]),
      _: 1
    })
  ]))
}